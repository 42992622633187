import { Summary } from "lib/service/client/FrontTopPageService";
import React, { FC, HTMLAttributes, useMemo } from "react";
import SsHeadline from "../layout/SsHeadline";
import { TopPageProject } from "interfaces/front/page/top";
import styled from "styled-components";
import ProjectCard from "./ProjectCard";
import Link from "next/link";

export type Props = HTMLAttributes<HTMLElement> & { projects: TopPageProject[]; summaries?: Summary[] };
const CrowdFundingProjectsSection: FC<Props> = ({ projects, summaries, ...props }) => {
  const projectSummaryPairs = useMemo(() => {
    return projects.map((project) => {
      const summary = summaries?.find((summary) => summary.project_id === Number(project.id));
      return { project, summary };
    });
  }, [projects, summaries]);
  return projects.length > 0 ? (
    <section {...props}>
      <SsHeadline color="white" label={<Logo src="img/crowd_funding_logo.png" />} />
      <CardList>
        {projectSummaryPairs.map((pair, index) => (
          <ProjectCard key={index} project={pair.project} summary={pair.summary} />
        ))}
      </CardList>
      <BannerArea>
        <Link href="/lp/kaleido">
          <BannerImage
            src="https://animefund.com/project-preview/temp/kaleido20th_banner.jpg"
            alt="『カレイドスター20周年アニバーサリー』応援クラウドファンディング"
            loading="lazy"
          />
        </Link>
      </BannerArea>
    </section>
  ) : (
    <></>
  );
};
export default CrowdFundingProjectsSection;

const Logo = styled.img`
  @media screen and (max-width: 960px) {
    height: 21px;
  }
  @media screen and (min-width: 961px) {
    height: 36px;
  }
`;
const CardList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media screen and (min-width: 961px) {
    &:after {
      content: "";
      display: block;
      width: calc((100% - 30px) / 3);
    }
  }
`;
const BannerArea = styled.div`
  margin: 20px 20px 0;
  display: flex;
  @media screen and (max-width: 960px) {
    justify-content: center;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 961px) {
    justify-content: center;
  }
`;
const BannerImage = styled.img`
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
  @media screen and (max-width: 960px) {
    margin-bottom: 10px;
    width: 300px;
  }
  @media screen and (min-width: 961px) {
    width: 400px;
  }
`;
